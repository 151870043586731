import { defineStore } from 'pinia'
import api from '@/plugins/api'
import storage from '@/plugins/storage'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authUser: null,
  }),
  getters: {
    user: state => state.authUser,
    isLoggedIn: state => !!state.authUser && !!state.authUser.token,
  },
  actions: {
    async login (credentials) {
      try {
        const { data } = await api.post('https://accounter.r1.tsst.su/api/auth', credentials) // ToDo api URL
        const { group, login, session, token } = data
        storage.SET('r1_session', { group, login, session, token })
        this.authUser = { group, login, session, token }
        return data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    logout () { // async
      storage.REMOVE('r1_session')
      this.authUser = null
    },
  },
})
