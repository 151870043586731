import { createRouter, createWebHistory } from 'vue-router'

import AuthMiddleware from '@/middleware/authMiddleware'
import GuestMiddleware from '@/middleware/guestMiddleware'

import DefaultLayout from '../layouts/DefaultLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/components/Dashboard.vue'),
    meta: {
      layout: DefaultLayout,
      middleware: AuthMiddleware,
      breadcrumb: 'Дашборд',
    },
  },
  {
    path: '/path-1',
    name: 'path1',
    component: () => import('@/components/Path1.vue'),
    meta: {
      layout: DefaultLayout,
      middleware: AuthMiddleware,
      breadcrumb: 'Раздел 1',
    },
  },
  {
    path: '/path-2',
    name: 'path2',
    component: () => import('@/components/Path2.vue'),
    meta: {
      layout: DefaultLayout,
      middleware: AuthMiddleware,
      breadcrumb: 'Раздел 3',
    },
  },
  {
    path: '/path-3',
    name: 'path3',
    component: () => import('@/components/Path3.vue'),
    meta: {
      layout: DefaultLayout,
      middleware: AuthMiddleware,
      breadcrumb: 'Раздел 3',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/LoginPage.vue'),
    meta: {
      layout: EmptyLayout,
      middleware: GuestMiddleware,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/pages/NotFound.vue'),
    meta: {
      layout: EmptyLayout,
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

function nextFactory (context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) {
    return context.next
  }

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = { from, next, router, to }
    const nextMiddleware = nextFactory(context, middleware, 1)

    middleware[0]({ ...context, next: nextMiddleware })
  } else {
    next()
  }
})

export default router
