import { useRouter } from 'vue-router'
import storage from '@/plugins/storage'

export default function guestMiddleware (context) {
  const router = useRouter()
  const authData = storage.GET('r1_session')

  if (authData && authData.token) {
    return router.push('/dashboard')
  }

  context.next()
}
