import { useRouter } from 'vue-router'
import storage from '@/plugins/storage'

export default function authMiddleware (context) {
  const router = useRouter()
  const authData = storage.GET('r1_session')

  if (!authData || !authData.token) {
    router.push('/login')
  }
  context.next()
}
