const getNestedValue = (obj, path) => {
  if (!path.length) {
    return obj
  }

  const key = path.shift()
  return getNestedValue(obj[key], path)
}

export default {
  // eslint-disable-next-line consistent-return
  GET: name => {
    const value = localStorage.getItem(name)
    if (value) return JSON.parse(value)
  },
  SET: (name, item) => {
    const stringifiedItem = JSON.stringify(item)
    localStorage.setItem(name, stringifiedItem)
  },
  REMOVE: name => {
    localStorage.removeItem(name)
  },
  getOptions: (optionsName, field, defaultValue = 0) => {
    const value = localStorage.getItem(optionsName)

    if (value) {
      const parsedValue = JSON.parse(value)
      if (field) {
        return getNestedValue(parsedValue, field.split('.'))
      }
      return parsedValue
    }
    return defaultValue
  },
}
