import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const options = {
  timeout: 2000,
  position: 'bottom-right',
}

export default function installToast (app) {
  app.use(Toast, options)
}
