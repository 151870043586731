/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'
// import Toast from 'vue-toastification'
// import 'vue-toastification/dist/index.css'
// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// const options = {
//   timeout: 2000,
//   position: 'bottom-right',
// }

const app = createApp(App)

registerPlugins(app)

// app.use(Toast, options)

app.mount('#app')
