<script setup>
  import { ref } from 'vue'
  import { useAuthStore } from '@/stores/auth'
  import { useRoute, useRouter } from 'vue-router'

  const authStore = useAuthStore()
  const router = useRouter()
  const route = useRoute()
  const allRoutes = router.getRoutes()

  const breadcrumbs = computed(() => {
    const pages = route.path.split('/').splice(1)
    const breadcrumbs = []

    let currentRoute = allRoutes.find(item => item.name === route.name)
    for (let i = 0; i < pages.length; i += 1) {
      breadcrumbs.push({
        title: currentRoute.meta.isDynamic
          ? `${currentRoute.meta.breadcrumb}${pages[pages.length - 1 - i]}`
          : currentRoute.meta.breadcrumb,
        to: `/${pages.slice(0, pages.length - i).join('/')}`,
      })

      const parent = currentRoute.meta.parent
      if (parent) {
        currentRoute = allRoutes.find(item => item.path === parent)
      } else {
        break
      }
    }

    return breadcrumbs.reverse()
  })
  const isMenuShown = ref(null)
  const navLinks = [
    {
      icon: 'mdi-view-dashboard',
      text: 'Дашборд',
      route: '/',
    }, {
      icon: 'mdi-domain',
      text: 'Раздел 1',
      route: 'path-1',
    }, {
      icon: 'mdi-domain',
      text: 'Раздел 2',
      route: 'path-2',
    }, {
      icon: 'mdi-domain',
      text: 'Раздел 3',
      route: 'path-3',
    }]

  const handleLogout = () => {
    authStore.logout()
    router.push('/login')
  }
</script>

<template>
  <v-app>
    <v-navigation-drawer v-model="isMenuShown">
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-list-item>
              <v-list-item-title class="font-weight-bold">
                R1.Бухгалтер
              </v-list-item-title>

              <v-list-item-subtitle>
                <span v-if="authStore.user">
                  {{ authStore.user.login }}
                </span>
              </v-list-item-subtitle>
            </v-list-item>

            <v-divider />

            <div
              v-for="(link, i) in navLinks"
              :key="i"
            >
              <v-list-item :to="link.route">
                <template #prepend>
                  <v-icon :icon="link.icon" />
                </template>
                <v-list-item-title>
                  {{ link.text }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-col>
      </v-row>

      <template #append>
        <div class="pa-2">
          <v-btn
            block
            @click="handleLogout"
          >
            Выход
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="isMenuShown = !isMenuShown" />

      <v-breadcrumbs
        class="font-weight-bold"
        :items="breadcrumbs"
      />
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
