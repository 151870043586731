import axios from 'axios'

function objectToQuery (obj, exclude = '', acceptableValues = []) {
  let qs = ''

  for (const name in obj) {
    if ((obj[name] || acceptableValues.includes(obj[name])) && name !== exclude) {
      if (Array.isArray(obj[name])) {
        obj[name].forEach(val => {
          if (val) {
            qs += `${name}=${val}&`
          }
        })
      } else {
        qs += `${name}=${obj[name]}&`
      }
    }
  }

  return qs.slice(0, -1)
}

export default {
  async get (url, payload) {
    const response = await axios.get(url, {
      params: payload,
      paramsSerializer: params => objectToQuery(params),
    })

    return response
  },

  async post (url, payload) {
    const response = await axios.post(url, payload)

    return response
  },
}
